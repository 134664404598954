<template>
  <div class="main">
    <Chatting v-if="chat" @close="chat=false" @add="addNewChat" :chatLoaded="chatLoaded" :msgs="msgs" :seq="selectedExam.seq" :hospitalName="selectedExam.hospital?.name"/>
    <ModalHistory @close_modal="modalState = false" @set_exam="showClickedPrevExam" v-if="modalState" :prevExams="prevExams"/>
    <div class="sidebar">
      <a @click="$router.go()" class="item_padding refresh_logo">
        <img src="https://invisionlab.xyz/static/img/PanviRDWhite.svg" alt="logo_img">
      </a>
      <div class="state_button_wrap">
        <button @click="getFilteredExams('pending')" :class="[filterState=='pending'?'btn_selected':'white_black']">판독대기</button>
        <button @click="getFilteredExams('completed')" :class="[filterState=='completed'?'btn_selected':'white_black']">판독완료</button>
        <button @click="getFilteredExams('unreadable')" :class="[filterState=='unreadable'?'btn_selected':'white_black']">판독불가</button>
        <button @click="getFilteredExams('all')" :class="[filterState=='all'?'btn_selected':'white_black']">전체</button>
      </div>
      <input type="text" @input="keywordFilter" :value="keyword" placeholder="Search..." class="search_input">
      <div class="exam_table">
        <div class="no_exam" v-if="noExam" style="color:white">검색 결과가 없습니다.</div>
        <a @click="setExam(exam)" :class="[__returnWhatDay(exam.requested_at) == 0?'today':(__returnWhatDay(exam.requested_at) == -1? 'yesterday' : (__returnWhatDay(exam.requested_at) == -2 ? 'two_days_ago' : (__returnWhatDay(exam.requested_at) == -3 ? 'more_than_three_days_ago':''))), 'exam_row','item_padding',exam.seq == selectedExam.seq?'selected_exam_animation':'']" v-for="(exam,idx) in exams" :key="'exams-'+idx+'-'+'id-'+exam.seq">
          <div class="row_container">
            <div class="opacity x_ray">
              <img v-if="exam.type=='CT'" src="https://invisionlab.xyz/static/img/CT_icon.png" alt="CT_img">
              <img v-else src="https://invisionlab.xyz/static/img/PX_icon.png" alt="PX_img">
            </div>
            <div class="opacity xray_exp">
              <span>{{`${new Date(exam.taken).getFullYear()}-${(new Date(exam.taken).getMonth()+1).toString().length == 1 ? '0'+(new Date(exam.taken).getMonth()+1).toString():new Date(exam.taken).getMonth()+1}-${(new Date(exam.taken).getDate()).toString().length == 1 ? '0'+(new Date(exam.taken).getDate()).toString() : new Date(exam.taken).getDate()}`}} </span>
              <span>{{new Date(exam.taken).toTimeString().slice(0,8)}}</span><br>
              {{exam.hospital.name}} <br>
              {{exam.patient_name}}
            </div>
          </div>
          <div v-if="exam._new_chat" class="img_position new_chat_img"></div>
          <div v-else-if="exam.chat" class="img_position chat_img"></div>
        </a>
      </div>
    </div>
    <div class="viewer">
      <div class="input_page">
        <div class="labeler">
          <div class="main_img" ref="imgBox">
            <div v-if="!selectedExam.type" :style="{width:`${imgSize.width}px`,height:`${imgSize.height}px`}" class="preview_img imgFrame"></div>
            <div v-else-if="selectedExam.type==='CT'" :style="{backgroundImage:`url(${baseUrl+ctImgUrl})`, width:`${imgSize.width}px`,height:`${imgSize.height}px`}" class="crosshair imgFrame">
              <div ref="bBoxCanvas" @mousedown="mousedownOnCanvas" @mousemove="createBBox" @mouseup="appendBBox" class="b_box_transparent">
                <div style="position:absolute" v-for="(label,idx) in selectedExam.labels.disease" :key="'labels-'+idx+'-'+label.disease">
                  <div v-show="rangeInputVal==bbox.image&&showBbox" class="b_box" @mouseenter="mouseEnterBbox(label)" @mouseleave="mouseleaveBbox" :style="{width:`${(bbox.br?.x - bbox.lt?.x)/imgRatio.width}px`,height:`${(bbox.br?.y - bbox.lt?.y)/imgRatio.height}px`,top:`${bbox.lt?.y/imgRatio.height}px`,left:`${bbox.lt?.x/imgRatio.width}px`}" v-for="(bbox,i) in label.bboxes" :key="'bboxes-'+i+'-'+'bbox'">
                    {{label.disease}}
                    <div v-show="(targetBboxId==label.id&&!isStarted) || ((targetBboxId==label.id)&&(targetBboxId==drawingBboxId)&&isStarted)" :id="label.id" class="remove_btn">x</div>
                  </div>
                </div>
                <div class="b_box" :style="{width:`${diseaseTemp.br.x - diseaseTemp.lt.x}px`,height:`${diseaseTemp.br.y - diseaseTemp.lt.y}px`,top:`${diseaseTemp.lt.y}px`,left:`${diseaseTemp.lt.x}px`}" v-for="(diseaseTemp,idx) in diseasesTemp" :key="'diseasesTemp-'+idx+'-'+diseasesTemp.disease"></div>
              </div>
            </div>
            <div ref="bBoxCanvas" v-else-if="selectedExam.type==='PX'" :style="{backgroundImage:`url(${baseUrl+selectedExam.url})`, width:`${imgSize.width}px`,height:`${imgSize.height}px`}" class="crosshair imgFrame">
              <div @mousedown="mousedownOnCanvas" @mousemove="createBBox" @mouseup="appendBBox" class="b_box_transparent">
                <div @mouseenter="mouseEnterBbox(bbox)" @mouseleave="mouseleaveBbox" v-show="showBbox" class="b_box" :style="{width:`${(bbox.br?.x - bbox.lt?.x)/imgRatio.width}px`,height:`${(bbox.br?.y - bbox.lt?.y)/imgRatio.height}px`,top:`${bbox.lt?.y/imgRatio.height}px`,left:`${bbox.lt?.x/imgRatio.width}px`}" v-for="(bbox,idx) in selectedExam.labels.disease" :key="'bbox-'+idx+'-'+bbox.disease">
                  {{bbox.disease}}
                  <div v-show="!isStarted && (targetBboxId == bbox.id)" :id="bbox.id" class="remove_btn">x</div>
                </div>
                <div class="b_box" :style="{width:`${diseaseTemp.br.x - diseaseTemp.lt.x}px`,height:`${diseaseTemp.br.y - diseaseTemp.lt.y}px`,top:`${diseaseTemp.lt.y}px`,left:`${diseaseTemp.lt.x}px`}" v-for="(diseaseTemp,idx) in diseasesTemp" :key="'diseasesTemp-'+idx+'-'+diseasesTemp.disease"></div>
              </div>
            </div>
          </div>
          <div class="range_wrapper" v-if="selectedExam.type == 'CT' || !selectedExam.type">
            <input :style="{background : `linear-gradient(to right, dodgerblue 0%, dodgerblue ${val}%, #fff ${val}%, #fff 100%)`}" type="range" min="1" :max="maxRangeNum" step="1" v-model="rangeInputVal">
          </div>
        </div>
        <div class="label_tool">
          <div class="exam_info">
            <div class="exam_info_item">
              <p>상태</p>
              <p v-if="selectedExam.status">{{selectedExam.status=='pending'?'판독대기':(selectedExam.status=='completed'?'판독완료':'판독불가')}}</p>
            </div>
            <div class="exam_info_item">
              <p>병원명</p>
              <p>{{selectedExam.hospital?.name}}</p>
            </div>
            <div @click="getPrevExams" :class="['exam_info_item',selectedExam.chart_id?'chart_num_pointer':'']">
              <p>차트번호</p>
              <p>{{selectedExam.chart_id}}</p>
            </div>
            <div class="exam_info_item">
              <p>이름</p>
              <p>{{selectedExam.patient_name}}</p>
            </div>
            <div class="exam_info_item">
              <p>나이</p>
              <p>{{selectedExam.patient_age}}</p>
            </div>
            <div class="exam_info_item">
              <p>성별</p>
              <p>{{selectedExam.patient_gender}}</p>
            </div>
            <div class="exam_info_item">
              <p>촬영일시</p>
              <p>
                <span>{{selectedExam.taken && (`${new Date(selectedExam.taken).getFullYear()}-${(new Date(selectedExam.taken).getMonth()+1).toString().length == 1 ? '0'+(new Date(selectedExam.taken).getMonth()+1).toString():new Date(selectedExam.taken).getMonth()+1}-${(new Date(selectedExam.taken).getDate()).toString().length == 1 ? '0'+(new Date(selectedExam.taken).getDate()).toString() : new Date(selectedExam.taken).getDate()}`)}} </span>
                <span>{{selectedExam.taken && new Date(selectedExam.taken).toTimeString().slice(0,8)}}</span>
              </p>
            </div>
            <div class="exam_info_item">
              <p>요청일시</p>
              <p>
                <span>{{selectedExam.requested_at && (`${new Date(selectedExam.requested_at).getFullYear()}-${(new Date(selectedExam.requested_at).getMonth()+1).toString().length == 1 ? '0'+(new Date(selectedExam.requested_at).getMonth()+1).toString():new Date(selectedExam.requested_at).getMonth()+1}-${(new Date(selectedExam.requested_at).getDate()).toString().length == 1 ? '0'+(new Date(selectedExam.requested_at).getDate()).toString() : new Date(selectedExam.requested_at).getDate()}`)}} </span>
                <span>{{selectedExam.requested_at && new Date(selectedExam.requested_at).toTimeString().slice(0,8)}}</span>
              </p>
            </div>
          </div>
          <div class="select_row">
            <select v-model="diseaseName" class="disease_select" :disabled="!selectedExam.seq || isStarted">
              <option :disabled="idx===0" :value="diseaseItem" v-for="(diseaseItem,idx) in diseaseList" :key="'diseaseList-'+idx+'-'+diseaseItem">{{diseaseItem}}</option>
            </select>
            <button @click="finishReading" class="label_end_btn item_padding" :disabled="!isStarted || selectedExam.type == 'PX'">종료</button>
          </div>
          <div class="label_button_wrap">
            <button class="label_remove_btn item_padding" @click="resetLabels" :disabled="!selectedExam.seq || selectedExam.labels.disease.length == 0 || isStarted">초기화</button>
            <button class="bg_purple item_padding" :disabled="!selectedExam.seq">AI예측</button>
            <button class="bg_blue item_padding" @click="changeShowBbox" :disabled="!selectedExam.seq || selectedExam.labels.disease.length == 0">box<br>{{showBbox?'감춤':'보임'}}</button>
          </div>
          <div class="exam_info_item">
            <p>LabelID</p>
            <p>0</p>
          </div>
        </div>
      </div>
      <div class="write_page">
        <div class="cc_column">
          <div class="text_grid">
            <h3>C.C.</h3>
            <textarea @blur="focusoutFromTextarea" class="cc_textarea" v-model="cc"></textarea>
          </div>
          <div class="text_grid">
            <h3>Finding</h3>
            <textarea @blur="focusoutFromTextarea" class="finding_textarea" v-model="finding"></textarea>
          </div>
        </div>
        <div class="result_column">
          <div class="text_grid">
            <h3>Conclusion</h3>
            <textarea @blur="focusoutFromTextarea" class="conclusion_textarea" v-model="conclusion"></textarea>
          </div>
          <div class="text_grid">
            <h3>Recommend</h3>
            <textarea @blur="focusoutFromTextarea" class="recommend_textarea" v-model="recommend"></textarea>
          </div>
        </div>
        <div class="button_column">
          <div class="highlight_box">
            <div class="flex">
              <input @blur="focusoutFromTextarea" v-model="highlight" :checked="highlight" @click="changeHighlight" type="checkbox">
            </div>
            <p>특이소견</p>
          </div>
          <div class="button_wrap">
            <button :disabled="!selectedExam.seq || isStarted" @click="completeReading" :class="[selectedExam.status == 'completed' ? 'bg_purple': 'bg_green']">판독완료</button>
            <button :disabled="!selectedExam.seq || isStarted" @click="refuseReading" class="bg_red">판독불가</button>
            <button :disabled="!selectedExam.seq || isStarted" @click="openChat" class="chat_btn bg_blue">채팅시작</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import wsMixin from '@/utils/wsMixin';
import ModalHistory from'../components/ModalHistory.vue';
import diseaseList from '../diseaseList.js';
import Chatting from '@/components/Chatting.vue'
import chatAlarm from '@/assets/audio/채팅 수신음.mp3'

export default {
  name: "PageMain",
  mixins : [wsMixin],
  components : {
    ModalHistory,
    Chatting
  },
  data(){
    return{
      exams : [],
      selectedExam : {},
      isStarted : false,
      diseaseList : diseaseList,
      diseaseName : '--질환을 선택해주세요--',
      cc : '',
      finding : '',
      conclusion : '',
      recommend : '',
      highlight : false,
      modalState : false,
      prevExams: [],
      rangeInputVal : 1,
      maxRangeNum : 0,
      startPoint : { x: 0, y: 0 }, // bbox 시작 위치
      endPoint : { x: 0, y: 0 }, // bbox 종료 위치
      imgBoxSize :{ width: 0, height: 0}, // 이미지가 차지할 수 있는 공간의 크기
      imgSize : { width: 0 , height: 0 }, // imgBoxSize 크기 내에서 실제 이미지의 크기에 비례하여 최대한 차지할 수 있는 크기
      imgRatio : { width: 0, height: 0 },
      ctImgUrl : '',
      bboxInfo : { lt: { x: 0, y: 0 } ,br: { x: 0, y: 0 } },
      diseasesTemp : [],
      val : 0,
      baseUrl : (process.env.NODE_ENV=='production'?process.env.VUE_APP_STATIC_URL:process.env.VUE_APP_STATIC_DEV_URL),
      bboxIdArr : [],
      targetBboxId : 0,
      drawingBboxId : -1,
      showBbox : true,
      noExam : false,
      keyword : '',
      filterState : 'all',
      nowExam:{},
      chat : false,
      msgs: [],
      keywordTimer : undefined,
      chatTimer: undefined,
      chatLoaded: false
    }
  },
  methods : {
    onRecv(data){
      // selectedExam의 seq와 같은 seq를 가진 객체를 exams에서 찾아, 그 객체를 저장한다.
      let isInExams = this.exams.find((exam)=>exam.seq == this.selectedExam.seq);
      /*
      ** 11번 액션 (= 로그인) 에 대한 응답이 수신되었을 때의 처리
      ** 로그인이 성공했음을 isLoggedIn 에 boolean값으로 저장한 후 94번 액션(= 판독의뢰 영상목록 조회)을 보낸다.
      */
      if(data.action == 11){
        if( data.body[0].content.success ){
          this.$store.state.socket.isLoggedIn = true;
          let exams = this.$$build(94,[]);
          this.$socket.send(exams);
        }
      }
      /*
      ** 94번 액션(= 판독의뢰 영상목록 조회)에 대한 응답이 수신되었을 때의 처리
      ** id == 0 Main컴포넌트가 마운트 되었을 때, 판독의뢰된 모든 상태의 exams를 요청한 경우
      ** id == 1 버튼을 통해서 판독 상태에 따른 exams를 요청한 경우
      ** id == 2 keywordFilter를 통해서 exams를 요청한 경우
      ** id == 3 현재 보고있는 환자의 이전 영상 목록을 요청하는 경우
      */
      if(data.action == 94){
        /*
        ** 응답이 성공적일 경우,
        ** 응답으로 받은 영상목록 중에서 _new_chat == true 인 객체와 _new_chat == false 인 객체를 분류한다.
        ** 새로운 채팅이 있는 영상객체가 상위에 위치해 보이도록 this.exams 에 _new_chat == true인 객체들을 우선 대입한다.
        */
        if( data.body[0].content.success ){
          const recvExams = [...data.body[1].content];
          if(data.id == 3) {
            this.prevExams = [...recvExams];
            this.modalState = true;
          }else {
            const newChatExams = recvExams.filter(exam => exam._new_chat );
            const noNewChatExam = recvExams.filter(exam => !exam._new_chat );
            this.exams = [...newChatExams, ...noNewChatExam];
            /* 
            ** 버튼을 통해서 판독 상태에 따른 영상목록을 요청한 경우, keywordFilter를 통해서 영상목록을 요청한 경우에
            ** 응답으로 받은 영상목록의 길이가 0일 때, 응답으로 받은 영상이 없다는 상태를 저장하기 위해서 noExam 에 true 값을 대입한다.
            */
            if(data.id == 1 && data.body[1].content.length==0 || data.id == 2 && data.body[1].content.length==0) this.noExam = true;
            // 응답으로 받은 영상목록의 길이가 0이 아닐 때, 응답으로 받은 영상이 있다는 상태를 저장하기 위해서 noExam 에 false 값을 대입한다.
            else this.noExam = false;
            
            /* 
            ** 버튼을 통해서 판독 상태에 따른 영상목록을 요청한 경우에는 판독 상태에 따른 영상만을 요청한 것이므로 
            ** keyword를 담고있는 this.keyword의 길이가 0보다 큰 경우에 빈 문자열을 대입한다.
            */
            if(data.id == 1 && this.keyword.length > 0) this.keyword = '';
          }
          
          if( process.env.VUE_APP_DEBUG ){
            console.log('exams...',this.exams)
          }
        }else alert('영상목록을 불러오는 것에 실패하였습니다.');
      }
      /*
      ** 244번 액션(= 병변위치(label)입력) 에 대한 응답이 수신되었을 때의 처리
      ** id == 1 label 추가
      ** id == 2 label 제거
      ** id == 3 label 초기화
      */
      if(data.action == 244) {
        if(data.body[0].content.success) {
          this._clearPrevState();
          if(isInExams) isInExams.labels.disease = [...this.selectedExam.labels.disease];
          this._changeStatusToPending();
        }else {
          if((data.id == 1 && this.selectedExam.type == 'PX') || data.id == 2 || data.id == 3){
            this.selectedExam.labels.disease = [...this.nowExam.labels.disease];
            this._clearPrevState();
            if(data.id == 2) alert('질환부위 제거에 실패하였습니다.\n다시 시도하세요');
            else if(data.id == 3) alert('라벨 초기화에 실패하였습니다.\n다시 시도하세요');
          }
          if(data.id == 1) alert('질환부위 선택에 실패하였습니다.\n다시 시도하세요');
        }
      }
      // 52번 액션(= 판독완료 처리) 에 대한 응답이 수신되었을 때의 처리
      if(data.action == 52){
        /*
        ** 응답이 성공인 경우에 대한 처리
        ** selectedExam의 status는 프론트단에서 'completed'로 이미 변경되었으므로
        ** isInExams == true인 경우, isInExams의 status를 selectedExam의 status와 같이 변경한다.
        */
        if(data.body[0].content.success){
          if(isInExams) {
            isInExams.status = this.selectedExam.status;
          }
        }
        /*
        ** 응답이 실패인 경우에 대한 처리
        ** selectedExam의 status는 프론트단에서 'completed'로 이미 변경되었으므로
        ** selectedExam의 status를 exam을 선택할 때 원본 데이터로써 저장해 놓은 this.nowExam 의 status와 같이 변경한다.
        */
        else {
          this.selectedExam.status = this.nowExam.status;
          alert('판독완료 처리가 실패하였습니다.\n다시 시도하세요');
        }
      }
      // 53번 액션(= 판독불가 처리)에 대한 응답이 수신되었을 때의 처리
      if(data.action == 53){
        /*
        ** 응답이 성공인 경우에 대한 처리
        ** selectedExam의 status는 이미 'unreadable' 로 변경되었으므로
        ** isInExams == true인 경우, isInExams의 status를 selectedExam의 status와 같이 변경한다.
        */
        if(data.body[0].content.success){
          if(isInExams) {
            isInExams.status = this.selectedExam.status;
          }
        }
        /*
        ** 응답이 실패인 경우에 대한 처리
        ** selectedExam의 status는 이미 'unreadable' 로 변경되었으므로
        ** selectedExam의 status를 exam을 선택할 때 원본 데이터로써 저장 해 놓은 this.nowExam의 status와 같이 변경한다.
        */
        else {
          this.selectedExam.status = this.nowExam.status;
          alert('판독불가 처리가 실패하였습니다.\n다시 시도하세요');
        }
      }
      // 71번 액션(= 판독소견서 입력)에 대한 응답이 수신되었을 때의 처리
      if(data.action == 71){
        /*
        ** 응답이 성공인 경우에 대한 처리
        ** 만약, isInExams == true인 경우, isInExams의 report와 highlight를 selectedExam의 report, highlight와 같이 변경한다.
        */
        if(data.body[0].content.success){
          if(isInExams){
            isInExams.report = {...this.selectedExam.report};
            isInExams.highlight = this.selectedExam.highlight;
          }
        }
        /*
        ** 응답이 실패한 경우에 대한 처리
        ** selectedExam의 report, highlight를 exam을 선택할 때 원본데이터로써 저장해 놓은 this.nowExam의 report, highlight와 같이 변경한다.
        */
        else {
          this.selectedExam.report = {...this.nowExam.report};
          this.selectedExam.highlight = this.nowExam.highlight;
        }
      }
      // 101번 액션(= 특정 영상정보에 대한 채팅메시지 열람)에 대한 응답이 수신되었을 때의 처리
      if(data.action == 101){
        /*
        ** 응답이 성공인 경우에 대한 처리
        ** 응답으로 받은 선택한 영상에 대한 이전 채팅목록을 msgs 에 대입한다.
        */
        if(data.body[0].content.success){
          this.msgs = [...data.body[0].content.list];
          this.chatLoaded = true;
          if(process.env.VUE_APP_DEBUG){
            console.log('msgs',this.msgs)
          }
        }else alert('채팅 목록을 불러오는 것에 실패하였습니다.');
      }
      // 102번 액션(= 특정 영상정보에 대한 채팅메시지 보내기)에 대한 응답이 수신되었을 때의 처리
      if(data.action == 102){
        /*
        ** 응답이 성공이면서 선택된 영상의 chat == false인 경우, selectedExam의 chat을 true로 변경한다.
        */
        if(data.body[0].content.success && !this.selectedExam.chat){
          this.selectedExam.chat = true;
          // isInExams == true인 경우, isInExams의 chat을 true로 변경한다.
          if(isInExams) isInExams.chat = true;
        }
      }
      // 103번 액션(= 채팅메시지 수신)에 대한 응답이 수신되었을 때의 처리
      if(data.action == 103){
        let audio = new Audio(chatAlarm);
        audio.onloadedmetadata = () => {
          /*
          ** chatTimer 가 falsy 값일 때의 처리
          ** 채팅 알림음을 play한 후, 알림음의 지속시간 이후에 실행되는 setTimeout을 호출하고, 그 반환값을 타이머에 대입한다.
          ** setTimeout()의 내부에는 setTimeout을 해제하고 falsy값을 할당하여 알림음이 지속되는 동안에는 새로운 채팅이 수신되어도 알림음이 울리지 않도록 한다.
          */
          if(!this.chatTimer) {
            audio.play();
            this.chatTimer = setTimeout(()=> {
              clearTimeout(this.chatTimer);
              this.chatTimer = undefined;
            },audio.duration * 1000)
          }
        }
        // 수신한 채팅의 exam 번호 와 같은 seq를 가진 객체를 exams에서 찾아 그 데이터를 저장한다.
        const nowExam = this.exams.find((exam)=> exam.seq == data.body[0].content.exam)
        /*
        ** nowExam 의 값이 truthy 인 경우에 대한 처리
        ** exams에서 수신한 채팅의 exam 번호 와 같은 seq를 가진 객체를 splice한후, 
        ** exams에서 수신한 채팅의 exam 번호와 같은 객체를 찾아(= nowExam) 그 객체를 exams에 unshift 한다.
        */
        if(nowExam){
          // nowExam 의 chat 이 false인 경우, 그 값을 true로 바꾼다.
          if(!nowExam.chat) nowExam.chat = true;
          const index = this.exams.findIndex(exam => exam.seq == data.body[0].content.exam);
          this.exams.splice(index,1);
          this.exams.unshift(nowExam);
        }
        /*
        ** 수신된 채팅의 exam 번호와 현재 선택한 selectedExam 이 같으면서 chat이 true인 경우를 제외한 상황에 대한 처리 
        ** 윗줄과 같은 상황은, 사용자가 수신된 채팅의 exam과 같은 exam을 선택하여 해당 exam의 채팅을 보고있는 경우를 제외한 것 이다.
        */
        if(!(this.selectedExam.seq == data.body[0].content.exam && this.chat)){
          // nowExam의 값이 truthy인 경우, _new_chat = true로 변경한다.
          if(nowExam) nowExam._new_chat = true;
          // 수신한 채팅의 exam 번호와, 현재 선택한 selectedExam의 seq가 같은 경우에 대한 처리
          if( this.selectedExam.seq == data.body[0].content.exam) {
            this.selectedExam._new_chat = true;
          }
        }
        // 수신한 채팅의 exam 번호와, 현재 선택한 selectedExam의 seq가 같은 경우에 대한 처리
        if( this.selectedExam.seq == data.body[0].content.exam){
          this.msgs.push({
            msg: data.body[0].content.msg,
            created: new Date().toISOString(),
            direction: "RD TO LABELER",
            written_by: {name:data.body[0].content.witten_by.name}
          })
          // selectedExam의 chat이 false인 경우에 대한 처리
          if(!this.selectedExam.chat) this.selectedExam.chat = true;
        }
      }
      // 105번 액션(= 채팅메시지 읽음처리)에 대한 응답이 수신되었을 때의 처리
      if(data.action == 105){
        // 응답이 성공인 경우에 대한 처리
        if(data.body[0].content.success){
          this.selectedExam._new_chat = false;
          /*
          ** isInExams가 truthy값인 경우에 대한 처리
          ** 새로운 채팅목록이 있는 exams과 아닌 exams 를 분류하고, 새로운 채팅목록이 없는 exams는 영상이 촬영된 날짜를 기준으로 내림차순으로 정렬하여 
          ** 채팅을 읽은 exam이 영상이 촬영된 날짜를 기준으로 제자리에 위치하도록 한다.
          */
          if(isInExams) {
            isInExams._new_chat = false;
            const newChatExam = this.exams.filter(exam => exam._new_chat );
            const noNewChatExam = this.exams.filter(exam => !exam._new_chat );
            noNewChatExam.sort((a,b) => new Date(b.taken).getTime() - new Date(a.taken).getTime());
            this.exams = [...newChatExam, ...noNewChatExam];
          }
          
        }
      }
    },
    onConnected(){
      const id = sessionStorage.getItem('id');
      const passwd = sessionStorage.getItem('passwd');
      // 로그인 처리를 하여, 이미 세션스토리지에 아이디와 비밀번호 값이 저장되어 있을 때의 처리
      if( id && passwd ) {
        let userInfo = this.$$build(
          11,
          [
            {
              contentType: 1,
              content: {type:"LABELER",email:id,passwd:passwd},
            }
          ]
        );
        this.$socket.send(userInfo);
      }
    },
    // labeler -> rd 채팅을 발신하는 것에 성공한 경우, 입력한 채팅 정보를 msgs에 추가한다.
    addNewChat(msg){
      this.msgs.push({
        created: new Date().toISOString(),
        msg,
        direction: 'LABELER TO RD'
      })
    },
    resize(originalImgSize){
      const ratio = originalImgSize[0] / originalImgSize[1];
      this.imgSize.width = this.imgBoxSize.width;
      this.imgSize.height = this.imgSize.width / ratio;

      if(this.imgSize.height > this.imgBoxSize.height) {
        this.imgSize.height = this.imgBoxSize.height;
        this.imgSize.width = this.imgSize.height * ratio;
      }

      this.imgRatio.width = originalImgSize[0] / this.imgSize.width;
      this.imgRatio.height = originalImgSize[1] / this.imgSize.height;
    },
    setExam(exam){
      this.nowExam = this.exams.find((item)=>item.seq == exam.seq);
      this.selectedExam = JSON.parse(JSON.stringify(exam));
      this._clearPrevState();
      const originalImgSize = [exam.url.split('.')[2],exam.url.split('.')[3]];
      this.resize(originalImgSize);
    },
    getPrevExams(){
      // selectedExam의 chart_id로 요청을 하여 해당 환자의 영상 목록을 요청한다.
      let exams = this.$$build(94,3,[{
        contentType : 1,
        content : {
          filter : {exam: [this.selectedExam.seq]}
        }
      }]);
      this.$socket.send(exams);
    },
    showClickedPrevExam(exam){
      this.modalState = false;
      this.setExam(exam)
    },  
    calculateBboxInfoToOriginalNum(bboxInfo){
      return {
        lt : { x: parseInt(bboxInfo.lt.x * this.imgRatio.width), y: parseInt(bboxInfo.lt.y * this.imgRatio.height) },
        br : { x: parseInt(bboxInfo.br.x * this.imgRatio.width), y: parseInt(bboxInfo.br.y * this.imgRatio.height) }
      }
    },
    mousedownOnCanvas(ev){
      // target이 remove button인 경우에 대한 처리
      if(ev.target.classList.contains('remove_btn')) this.removeBbox(ev);
      // target이 remove button이 아닌 경우에 대한 처리로, bbox의 시작점을 설정하는 함수를 호출한다.
      else this.setStartPoint(ev);
    },
    setStartPoint(ev){
      if(!this.isStarted) return;
      this.startPoint = {x : ev.clientX , y : ev.clientY};
      this.endPoint = { x: 0, y: 0 };
    },
    createBBox(ev){
      // bbox 시작지점의 x값이 0 인 경우에 함수를 종료한다.
      if(this.startPoint.x == 0) return;
      const currentTargetCoord = this.$refs.bBoxCanvas.getBoundingClientRect();
      this.endPoint = { x : ev.clientX, y : ev.clientY };
      this.bboxInfo.lt.x =  Math.min(this.startPoint.x,this.endPoint.x)-currentTargetCoord.x;
      this.bboxInfo.lt.y = Math.min(this.startPoint.y,this.endPoint.y)-currentTargetCoord.y;
      this.bboxInfo.br.x = Math.max(this.startPoint.x,this.endPoint.x)-currentTargetCoord.x;
      this.bboxInfo.br.y = Math.max(this.startPoint.y,this.endPoint.y)-currentTargetCoord.y;
      /*
      ** mousemove 이벤트가 발생하는 위치가 bbox를 그릴 수 있는 이미지를 벗어나는 경우에 대한 처리
      ** 그리고 있던 bbox의 좌표, bbox정보 등을 초기화하여 다시 그릴 수 있도록 한다
      */

      if(
        this.endPoint.x <= currentTargetCoord.x + 5 || this.endPoint.x >= currentTargetCoord.x +currentTargetCoord.width - 5 ||
        this.endPoint.y <= currentTargetCoord.y + 5 || this.endPoint.y >= currentTargetCoord.y +currentTargetCoord.height - 5
      ){
        this.diseasesTemp = [];
        this.bboxInfo = { lt: { x: 0, y: 0 } ,br: { x: 0, y: 0 } };
        this.startPoint = { x : 0, y : 0 };
        this.endPoint = { x : 0 , y : 0 };
        return;
      }
      
      this.diseasesTemp.push({
        disease : this.diseaseName,
        lt : {...this.bboxInfo.lt},
        br : {...this.bboxInfo.br},
      });
      /*
      ** bbox를 그리는 좌표를 담고있는 diseasesTemp의 길이가 2 이상인 경우에 대한 처리
      ** shift() 를 호출하여 이전 좌표객체를 없애주어 push된 좌표객체의 위치에 맞는 bbox를 화면에 보이도록 한다.
      */
      if(this.diseasesTemp.length > 1){
        this.diseasesTemp.shift();
      }
    },
    appendBBox(){
      // 레이블링중이 아니거나 bbox 정보객체의 lt.x 좌표가 0 인 경우에는 함수를 종료한다.
      if(!this.isStarted || this.bboxInfo.lt.x == 0 ) return;

      this.diseasesTemp = [];

      // bbox의 width, height가 10px 이하인 경우에는 bbox 좌표, bboxInfo 를 초기화 하여 무시하도록 한다. 
      if(this.bboxInfo.br.x - this.bboxInfo.lt.x <= 10 && this.bboxInfo.br.y - this.bboxInfo.lt.y <= 10){
        this.bboxInfo = { lt: { x: 0, y: 0 } ,br: { x: 0, y: 0 } };
        this.startPoint = { x : 0, y : 0 };
        this.endPoint = { x : 0 , y : 0 };
        return;
      }
      
      // bbox 좌표를 원본 이미지의 비율에 맞추어 변환한다.
      const calculatedCoords = this.calculateBboxInfoToOriginalNum(this.bboxInfo);
      /*
      ** 선택한 영상의 type 이 CT일 경우에 대한 처리
      ** selectedExam.labels에 이미지 번호, 원본이미지 비율로 계산된 좌표와 같은 bbox에 대한 정보를 담고있는 객체를 push한다.
      ** 하나의 레이블링에 대해서 질병이름을 저장하고, 이미지 번호 기준 오름차순으로 bbox 객체를 정렬한다.
      */
      if(this.selectedExam.type === 'CT'){
        this.selectedExam.labels.disease[this.selectedExam.labels.disease.length-1].bboxes.push({
          image:parseInt(this.rangeInputVal), 
          ...calculatedCoords,
        })
        this.selectedExam.labels.disease[this.selectedExam.labels.disease.length-1].disease = this.diseaseName;
        this.selectedExam.labels.disease[this.selectedExam.labels.disease.length-1].bboxes.sort((a,b)=>a.image-b.image)
      }
      /*
      ** 선택한 영상의 type 이 PX일 경우에 대한 처리
      ** selectedExam.labels에 아이디, 원본이미지 비율로 계산된 좌표, 질병명과 같은 bbox에 대한 정보를 담고있는 객체를 push한다.
      */
      else{
        this.selectedExam.labels.disease.push({
          id : this.getRandomBboxId(),
          disease : this.diseaseName,
          ...calculatedCoords,
        })
      } 
      this.drawingBboxId = this.selectedExam.labels.disease[this.selectedExam.labels.disease.length-1].id;
      this.startPoint = { x : 0, y : 0 };
      if(this.selectedExam.type == 'PX') this.finishReading();
    },
    finishReading(){
      /*
      ** this.selectedExam 의 type이 CT 이면서, selectedExam.labels의 마지막 인덱스에 있는 bboxes 의 길이가 2보다 작은 경우에 대한 처리
      ** 위와 같은 상황은 하나의 질환에 대해서 bbox를 1개만 그린것을 의미하므로, alert를 띄워 ct는 최소 2개 이상의 bbox를 그려야 함을 알린다.
      */
      if(this.selectedExam.type == 'CT' && this.selectedExam.labels.disease[this.selectedExam.labels.disease.length-1].bboxes.length < 2) {
        return alert('CBCT 영상은 질환부위를 감싸는 2개 이상의 절단면을 표시해야 합니다.');
      }
      let body = [
        {
          contentType : 1,
          content : {
            exam : this.selectedExam.seq,
            labels: {
              disease: this.selectedExam.labels.disease,
              implant: this.selectedExam.labels.implant
            }
          }
        },
      ];

      let bboxPacket =  this.$$build(244,1,body);
      this.$socket.send(bboxPacket);
    },
    getRandomBboxId(){
      let randomId = 0;
      // 조건식이 true인 경우 while문을 실행한다.
      while(this.selectedExam.seq){
        randomId = Math.floor(Math.random() * 100  + 1);
        /*
        ** selectedExam.labels 각 label의 아이디를 담고 있는 bboxIdArr 가 randomId를 포함하고 있지 않는 경우에 대한 처리
        ** randomId를 bboxIdArr에 push하고, 반복문을 종료한다.
        */
        if(this.bboxIdArr.indexOf(randomId) == -1){
          this.bboxIdArr.push(randomId);
          break;
        }else continue;
      }
      return randomId;
    },
    mouseEnterBbox({id}){
      this.targetBboxId = parseInt(id);
    },
    mouseleaveBbox(){
      this.targetBboxId = 0;
    },
    removeBbox(ev){
      /*
      ** 삭제를 확인하는 confirm 창에 대한 반환값이 truthy 인 경우에 대한 처리 
      ** 삭제할 bbox의 아이디를 removedBboxId에 저장하여 삭제하려는 label을 식별할 수 있도록 한다. 
      */ 
      if(confirm('선택한 질환을 제거하시겠습니까?')){
        this._alertToChangeStatusToPending();
        this.selectedExam.labels.disease = this.selectedExam.labels.disease.filter(label => label.id != ev.target.id);
        // 레이블링 중 삭제버튼을 클릭한 경우(CBCT를 레이블링 하는 중)
        if(this.isStarted) {
          this.endPoint = {x:0,y:0};
          this.bboxInfo = { lt: { x: 0, y: 0 } ,br: { x: 0, y: 0 } };
          this.bboxIdArr = this.bboxIdArr.filter((id) => id != ev.target.id);
          // 선택한 질환에 대한 label객체가 삭제되었으므로 새로 bbox를 그릴 수 있도록 label객체를 selectedExam.labels에 push한다.
          this.selectedExam.labels.disease.push({bboxes:[],id:this.getRandomBboxId()});
        }
        // 레이블링중이 아닌 경우에 대한 처리
        else{
          let body = [
            {
              contentType : 1,
              content : {
                exam : this.selectedExam.seq,
                labels: {
                  disease: this.selectedExam.labels.disease,
                  implant: this.selectedExam.labels.implant
                }
              }
            },
          ];

          let bboxPacket =  this.$$build(244,2,body);
          this.$socket.send(bboxPacket);
        }
      }
    },
    changeShowBbox(){
      this.showBbox = !this.showBbox
    },
    resetLabels(){
      // 레이블링중인 경우에 alert를 띄우며 함수를 종료한다.
      if(this.isStarted) return alert('레이블링을 종료하세요');
      if(!confirm('해당 영상의 모든 레이블링을 삭제하시겠습니까?')) return;
      this._alertToChangeStatusToPending();
      this.selectedExam.labels.disease = [];
      let body = [
        {
          contentType : 1,
          content : {
            exam : this.selectedExam.seq,
            labels: {
              disease: this.selectedExam.labels.disease,
              implant: this.selectedExam.labels.implant
            }
          }
        },
      ];

      let bboxPacket =  this.$$build(244,3,body);
      this.$socket.send(bboxPacket);
    },
    focusoutFromTextarea(){
      // selectedExam.seq 이 falsy 값인 경우 함수를 종료한다.
      if(!this.selectedExam.seq) return;
      // selectedExam의 report값이 없는 경우에 판독소견서 내용들이 작성되지 않고 해당 함수가 호출되었을 때 함수를 반환하여 요청을 보내지 않도록 한다. 
      if(!this.selectedExam.report.cc && !this.selectedExam.report.finding && !this.selectedExam.report.conclusion && !this.selectedExam.report.recommend){
        if(this.cc.length == 0 && this.finding.length == 0 && this.recommend.length == 0 && (this.highlight == this.selectedExam.highlight)) return;
      }
      // selectedExam의 report값이 있는 경우에 판독소견의 내용이 변경되지 않고, 해당 함수가 호출되었을 때 함수를 반환하여 요청을 보내지 않도록 한다. 
      else{
        if(this.selectedExam.report.cc == this.cc && this.selectedExam.report.finding == this.finding && this.selectedExam.report.conclusion == this.conclusion && this.selectedExam.report.recommend == this.recommend && this.selectedExam.highlight == this.highlight) return;
      }
      this.selectedExam.report.cc = this.cc;
      this.selectedExam.report.finding = this.finding;
      this.selectedExam.report.conclusion = this.conclusion;
      this.selectedExam.report.recommend = this.recommend;
      this.selectedExam.highlight = this.highlight;
      let reportPacket = this.$$build(71,[
        {
          contentType : 1,
          content : {exam:this.selectedExam.seq, cc:this.cc, finding:this.finding, conclusion:this.conclusion, recommend:this.recommend, highlight:this.highlight}
        }
      ])
      this.$socket.send(reportPacket);
    },
    completeReading(){
      // selectedExam.seq 이 falsy 값인 경우 함수를 종료한다.
      if(!this.selectedExam.seq) return;
      // 레이블링중일 경우에 alert를 띄우며 함수를 종료한다.
      if(this.isStarted) return alert('레이블링을 종료하세요');
      if(!confirm('판독을 완료하시겠습니까?')) return;
      this.selectedExam.status = "completed";
      let packet = this.$$build(52,[{contentType:1,content:{exam:this.selectedExam.seq}}]);
      this.$socket.send(packet);
    },
    refuseReading(){
      // selectedExam.seq 이 falsy 값인 경우 함수를 종료한다.
      if(!this.selectedExam.seq) return;
      // 레이블링중일 경우에 alert를 띄우며 함수를 종료한다.
      if(this.isStarted) return alert('레이블링을 종료하세요');
      if(!confirm('해당 영상을 판독불가 처리 하시겠습니까?')) return;
      this.selectedExam.status = "unreadable";
      let packet = this.$$build(53,[{contentType:1,content:{exam:this.selectedExam.seq}}]);
      this.$socket.send(packet);
    },
    changeHighlight(){
      this.highlight = !this.highlight;
    },
    getFilteredExams(type){
      let body = [];
      this.filterState = type;
      // status를 따로 작성하지 않는 경우에는 모든 상태를 조회하므로, type 이 all 인 경우를 제외하고, 선택한 status 를 요청하도록 작성한다.
      if(type != 'all'){
        body.push({
          contentType : 1,
          content : {
            filter : {status : type}
          }
        })
      }
      let packet = this.$$build(94,1,body);
      this.$socket.send(packet);
    },
    keywordFilter(ev){
      this.keyword = ev.target.value; 
      // timer 가 truthy값인 경우 timer를 해제한다.
      if(this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(()=>{
        let body = [{
          contentType : 1,
          content : {
            filter : {keyword : ev.target.value}
          }
        }];

        if(this.filterState != 'all') {
          body[0].content.filter.status = this.filterState;
        }
        
        let packet = this.$$build(94,2,body);
        this.$socket.send(packet);
      },300)
    },
    openChat(){
      // 레이블링중인 경우에는 alert를 띄우며 함수를 종료한다.
      if(this.isStarted) return alert('레이블링을 종료하세요');
      // selectedExam.seq 의 값이 falsy 인 경우에는 함수를 종료한다.
      if(!this.selectedExam.seq) return;
      this.chat = true;
    },
    _alertToChangeStatusToPending(){
      // 선택한 영상의 상태가 pending 이 아닌 상태에서 bbox를 추가, 삭제, 초기화 하는 경우에 해당 함수가 실행된다.
      if(this.selectedExam.status != 'pending') {
        alert('판독상태가 대기상태로 변경됩니다.\n판독 종료 후 판독완료 버튼을 클릭하세요.');
      }
    },
    _changeStatusToPending(){
      // 선택한 영상의 상태를 pending으로 변경하는 함수로, 이미 상태가 pending인 경우에는 함수를 종료한다.
      if(this.selectedExam.status == 'pending') return;
      this.selectedExam.status = 'pending';
      const isInExam = this.exams.find((exam) => exam.seq == this.selectedExam.seq);
      if(isInExam) isInExam.status = 'pending';
    },
    /**
     * 의뢰한 날짜로부터 어느정도 시간이 흘렀는지를 반환한다.
     * 0(= today), -1(=yesterday), -2(=two days ago), -3(=three days or more ago)
     */
    __returnWhatDay(date){
      let today = new Date();
      let todayTime = new Date(today.getFullYear(),today.getMonth(),today.getDate()).getTime();
      let comparedDay = new Date(date);
      let comparedDayTime = new Date(comparedDay.getFullYear(),comparedDay.getMonth(),comparedDay.getDate()).getTime();
      let diff = (todayTime - comparedDayTime) / (1000 * 60 * 60 * 24);
      if(diff == 0) return diff;
      else if(diff >= 1 && diff <= 2) return -1 * diff;
      else if(diff >= 3) return -3;
    },
    _preloading (imageArray) {
      let n = imageArray.length;
      // 이미지 array의 0번째 인덱스 부터 마지막 인덱스 까지 반복문을 실행하여 이미지를 로딩한다.
      for (let i = 0; i < n; i++) {
        let img = new Image();
        img.src = imageArray[i];
      }
    },
    _preloadImg(item){
      let imgArr = [];
      /*
      ** 선택된 exam의 이미지가 여러장인 경우, 모든 이미지의 url을 얻기 위하여 반복문을 실행한다.
      ** 모든 이미지의 시작은 1번이고, maxRangeNum은 각 exam의 마지막 이미지 번호이다. 
      */
      for(let imgNum=1; imgNum<=this.maxRangeNum; imgNum++){
        let url = item.url.split('.');
        url.splice(-3,1,imgNum.toString());
        url = url.join('.');
        url = this.baseUrl + url;
        imgArr.push(url);
      }
      this._preloading(imgArr);
    },
    // bbox관련 상태들을 초기화 한다.
    _clearPrevState(){
      this.diseaseName = this.diseaseList[0];
      this.startPoint = {x : 0, y: 0};
      this.endPoint = {x : 0, y: 0};
      this.bboxInfo = { lt: { x: 0, y: 0 } ,br: { x: 0, y: 0 } };
      let bboxIdArr = [];
      this.selectedExam.labels.disease.forEach((label)=>{
        label.id = this.getRandomBboxId();
        bboxIdArr.push(label.id);
      });
      this.bboxIdArr = [...bboxIdArr];
    },
  },
  mounted(){
    this.imgBoxSize = {width : this.$refs.imgBox.clientWidth, height : this.$refs.imgBox.clientHeight};
    const previewImgSize = [1367,951];
    this.resize(previewImgSize);
    // socket이 연결 되어있고, 로그인이 되어 있는 경우에 대한 처리
    if(this.$store.state.socket.isConnected && this.$store.state.socket.isLoggedIn) {
      let exams = this.$$build(94,[]);
      this.$socket.send(exams);
    }
    const id = sessionStorage.getItem('id');
    const passwd = sessionStorage.getItem('passwd');
    // 세션스토리지에 아이디, 비밀번호 정보가 없는 경우에 대한 처리
    if(!id&&!passwd) this.$router.push('/');
  },
  watch : {
    rangeInputVal(newRangeInputVal){
      this.val = (newRangeInputVal / this.maxRangeNum) * 100 ;
      let url = this.ctImgUrl.split('.');
      url.splice(-3,1,newRangeInputVal.toString());
      url = url.join('.');
      this.ctImgUrl = url;
    },
    selectedExam(newSelectedExam,prevSelectedExam){
      // selectedExam.chat 이 false 인 경우에 msgs에 빈 배열을 할당하여 msgs를 초기화 한다.
      if(!this.selectedExam.chat) this.msgs = [];
      /*
      ** selectedExam.chat 이 true이면서 이전 exam 과 현재 exam의 seq가 다른 경우에 대한 처리
      ** msgs에 빈 배열을 할당하여 msgs를 초기화 하고, 101번 액션을 보내어 채팅목록을 받아온다.
      */
      if(newSelectedExam.seq != prevSelectedExam.seq){
        this.msgs = [];
        this.chatLoaded = false;
        let packet = this.$$build(101,[{
          contentType: 1,
          content: {exam:newSelectedExam.seq}
        }])
        this.$socket.send(packet);
      }
      this.chat = false;
      this.rangeInputVal = 1;
      this.val = 0;
      this.ctImgUrl = newSelectedExam.url;
      this.showBbox = true;
      this.highlight = newSelectedExam.highlight;
      this.maxRangeNum = newSelectedExam.type=='CT'? newSelectedExam.url.split('.')[5] : 0;

      // 선택한 exam의 report에 값이 있는 경우에 대한 처리 
      if(newSelectedExam.report.cc || newSelectedExam.report.finding || newSelectedExam.report.conclusion || newSelectedExam.report.recommend){
        this.cc = newSelectedExam.report.cc;
        this.finding = newSelectedExam.report.finding;
        this.conclusion = newSelectedExam.report.conclusion;
        this.recommend = newSelectedExam.report.recommend;
      }
      else {
        this.cc = '';
        this.finding = '';
        this.conclusion = '';
        this.recommend = '';
      }

      // 선택한 exam 의 type 이 CT인 경우에 _preloadImg 함수를 호출하여 이미지를 미리 로딩한다.
      if(newSelectedExam.type == 'CT'){
        this._preloadImg(newSelectedExam);
      }
      if( process.env.VUE_APP_DEBUG ){
        console.log('selectedExam : ',newSelectedExam);
      }
    },
    diseaseName(changedDiseaseName){
      // 변경된 질환명이 diseaseList[0](= '--질환을 선택해주세요--') 와 다른 경우, isStarted 에 true를 할당하여 레이블링의 시작을 표시한다.
      if(changedDiseaseName != this.diseaseList[0]){
        this.isStarted = true;
      }
      // 변경된 질환명이 diseaseList[0](= '--질환을 선택해주세요--') 인 경우, isStarted 에 false를 할당하여 레이블링의 종료를 표시한다.
      else {
        this.isStarted = false;
      }
    },
    isStarted(changedIsStarted){
      /*
      ** 변경된 isStarted(= changedIsStarted) 이 true이고, selectedExam의 type 이 CT인 경우에 대한 처리
      ** selectedExam.labels에 id, bboxes 속성을 가진 label 객체를 push 하여 bbox를 그릴 때 해당 label 객체의 bboxes에 bbox 객체가 push되어 화면에 보이도록 한다.
      */
      if(changedIsStarted && this.selectedExam.type == 'CT'){
        this.selectedExam.labels.disease.push({bboxes:[],id:this.getRandomBboxId()});
      }
      if(changedIsStarted){
        this._alertToChangeStatusToPending();
      }
    },
  },
}
</script>

<style>
  .select_row{
    height: 100%;
    display: flex;
    column-gap: 10px;
  }
  .disease_select{
    flex: 1;
  }
  .remove_btn{
    position: absolute;
    width: 15px;
    height: 15px;
    top: -10px;
    right: -10px;
    color: white;
    background: black;
    line-height: 15px;
    text-align: center;
    border-radius: 50%;
    border: 2px solid white;
    font-size: 13px;
    cursor: pointer;
  }
  button{
    border: none;
    font-size: 15px;
  }
  textarea,select{
    outline: none;
    font-size: 15px;
  }
  .main{
    width: 100%;
    height: 100vh;
    display: grid;
    grid-template-columns: auto 1fr;
  }
  .sidebar{
    display: grid;
    grid-template-rows: 100px 50px 40px 1fr;
    width: 320px;
    height: 100%;
    background-color: #1A1A1B;
    border: none;
    overflow: hidden;
    z-index: 3;
  }
  .sidebar button:hover, 
  .sidebar>a:hover{
    color: #000;
    background-color: #ccc;
  }
  .btn_selected{
    color: #000;
    background-color: #ccc;
  }
  .item_padding {
    padding: 8px 16px;
  }
  .purple_btn{
    background: #9560F5;
  }
  .white_black{
    color: white;
    background-color: #000;
  }
  .state_button_wrap{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }
  .search_input{
    padding: 5px;
    font-size: 15px;
    border : 1px solid #ddd;
  }
  .exam_table{
    overflow-y: scroll;
    border: 0px solid red;
  }
  ::-webkit-scrollbar {
    width:6px;
    background-color: #555;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 3px;
    background-color: #666;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #555;
  }
  .exam_row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid transparent;
    cursor: pointer;
  }
  .today{
    background-color: rgb(27, 24, 24);
  }
  .yesterday{
    background-color: rgb(36, 15, 15);
  }
  .two_days_ago{
    background-color:rgb(43, 8, 8);
  }
  .more_than_three_days_ago{
    background-color:rgb(51, 0, 0);
  }
  .exam_row:hover{
    background-color: #616161;
    color: #fff
  }
  .row_container{
    padding: 0.01em 16px;
    text-align: left;
    display: flex;
    align-items: center;
    column-gap: 4px;
  }
  .x_ray{
    width: 65px;
    height: 65px;
  }
  .x_ray img{
    width: 100%;
    height: 100%;
  }
  .opacity{
    opacity: 0.6;
  }
  .xray_exp{
    height: 60px;
    margin-top: 5px;
    color: white;
    border: 0px solid red;
    font-size: 12px;
  }
  .viewer{
    display: grid;
    grid-template-rows: 1fr 400px;
    width: 100%;
    overflow: auto;
  }
  .input_page{
    padding: 0 10px;
    display: grid;
    grid-template-columns: 1fr 320px;
    grid-gap: 10px;
  }
  .labeler{
    display: grid;
    grid-template-rows: 1fr auto;
  }
  .labeler input[type=range]{
    -webkit-appearance: none;
    border-radius: 10px;
    width: 100%;
    height: 8px;
    box-sizing: border-box;
    background-color: #fff;
  }
  .labeler input[type=range]::-webkit-slider-runnable-track {
    border-radius: 10px;
    height: 8px;
  }
  .labeler input[type=range]::-webkit-slider-thumb{
    -webkit-appearance: none;
    margin-top: -4px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: rgb(0, 117, 255);
  }
  .range_wrapper{
    width: 100%;
    height: 16px;
    display: flex;
    align-items: center;
  }
  .main_img{
    width: 100%;
    height : 100%;
    display: grid;
    place-items: center center;
    box-sizing: border-box;
  }
  .main_img img {
    width: 100%;
    height: 100%;
  }
  .preview_img{
    background-image: url('https://invisionlab.xyz/static/img/preview.jpg');
  }
  .crosshair{
    cursor: crosshair;
  }
  .img_frame{
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }
  .imgFrame{
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .b_box_transparent{
    position: relative;
    width: 100%;
    height: 100%;
  }
  .b_box{
    position: absolute;
    border: 2px solid rgb(127, 255, 127);
    color: rgb(127, 255, 127);
    font-size: small;
  }
  .label_tool{
    display: grid;
    grid-template-rows: 1fr 40px 60px 30px;
    grid-gap: 5px;
  }
  .exam_info{
    display: grid;
  }
  .exam_info_item{
    display: grid;
    grid-template-columns: 70px 1fr;
    align-items: center;
    color: white;
  }
  .label_button_wrap{
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    grid-gap: 10px;
  }
  .label_button_wrap button{
    color: white;
    border: none;
    white-space: nowrap;
  }
  .button_wrap{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: space-between;
    box-sizing: border-box;
    padding-top: 20px;
  }
  .button_wrap button{
    height: 80px;
  }
  .label_button_wrap button:disabled,
  .button_wrap button:disabled,
  .select_row button:disabled{
    cursor: not-allowed;
    opacity: 0.3;
  }
  .label_start_btn{
    color: white;
    background-color: #4CAF50;
  }
  .label_end_btn{
    background-color: #2196F3; 
    color: white;
    border: none;
    white-space: nowrap;
  }
  .label_remove_btn{
    background-color: #f44336;
  }
  .write_page{
    display: grid;
    grid-template-columns: 1fr 1fr 150px;
    color: white;
  }
  .write_page>div{
    padding: 10px;
  }
  .write_page textarea{
    resize: none;
  }
  .cc_column{
    display: grid;
    grid-template-rows: 1fr 2fr;
  }
  .text_grid{
    display: grid;
    grid-template-rows: auto 1fr;
  }
  .result_column{
    display: grid;
    grid-template-rows: 1fr 1fr;
  }
  .highlight_box{
    display: flex;
    column-gap: 20px;
    width: 100%;
  }
  .highlight_box p{
    margin: 15px 0;
  }
  .highlight_box input{
    transform: scale(2);
  }
  .flex{
    display: flex;
  }
  .button_column{
    color: white;
    display: grid;
    place-items: end center;
    grid-template-rows: 60px auto;
  }
  .button_column button:hover, 
  .label_button_wrap button:hover{
    color: white;
    background-color: #000;
  }
  .bg_blue{
    color: white;
    background-color:#2196F3 ;
  }
  .bg_purple{
    color: white;
    background-color: #9c27b0;
  }
  .bg_green{
    color: white;
    background-color: #4CAF50;
  }
  .bg_red{
    color: white;
    background-color: #f44336;
  }
  .chart_num_pointer{
    cursor: pointer;
  }
  .chart_num_pointer:hover{
    background-color: rgba(87,78,224,0.33);
  }
  .no_exam{
    padding: 10px;
  }
  .refresh_logo{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .img_position{
    width: 40px;
    height: 40px;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .chat_img{
    background-image: url('https://panvi.kr/images/mn_msg-3d24fa96a5ded18e8bdf..png');
  }
  .new_chat_img{
    background-image: url('https://panvi.kr/images/mn_new_msg-9ca334463b8dd49b52e7..png');
  }
  .taken_time{
    font-size: 11px;
  }
  .selected_exam_animation{
    border-color: rgba(255, 255, 255, 0.486);
  }
</style>