<template>
  <div class="modal">
    <div class="modal_box">
      <button class="close_btn" @click="closeModal">
        <img src="https://panvi.kr/images/dise_pu_close-48048e892d290a5210d9..png" alt="close_button">
      </button>
      <h3 class="title">영상목록</h3>
      <div class="exam_box">
        <table class="exam_table">
          <thead>
            <tr>
              <th>종류</th>
              <th>진료번호</th>
              <th>이름</th>
              <th>촬영일시</th>
            </tr>
          </thead>
          <tbody>
            <tr @click="setExam(exam)" class="item_row" v-for="(exam,idx) in prevExams" :key="'prevExams-'+idx+'-'+'id-'+exam.chart_id">
              <td>{{exam.type}}</td>
              <td>{{exam.chart_id}}</td>
              <td>{{exam.patient_name}}</td>
              <td>
                <span>{{`${new Date(exam.taken).getFullYear()}-${(new Date(exam.taken).getMonth()+1).toString().length == 1 ? '0'+(new Date(exam.taken).getMonth()+1).toString():new Date(exam.taken).getMonth()+1}-${new Date(exam.taken).getDate().toString().length == 1 ? '0'+new Date(exam.taken).getDate().toString() : new Date(exam.taken).getDate()}`}} </span>
                <span>{{new Date(exam.taken).toTimeString().slice(0,8)}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="button_wrap">
        <button class="cancel_btn" @click="closeModal">취소</button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name : 'ModalHistory',
  props : {
    prevExams : Array
  },
  methods : {
    closeModal(){
      this.$emit('close_modal')
    },
    setExam(exam){
      this.$emit('set_exam',exam)
    }
  },
  
}
</script>

<style scoped>
  .modal{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: grid;
    justify-items: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.6);
    z-index: 11;
  }
  .modal_box{
    position: relative;
    width: 700px;
    height: 750px;
    padding: 40px;
    display: grid;
    grid-template-rows: 50px auto 60px;
    grid-row-gap: 10px;
    background-color: #1A1A1B;
    border: 1px solid #2E2E33;
    border-radius: 8px;
    box-sizing: border-box;
  }
  .close_btn{
    position: absolute;
    top: 40px;
    right: 48px;
    height: 24px;
    padding: 0;
    background:none;
  }
  .title{
    margin: 0;
    font-size: 24px;
    color: white;
  }
  .button_wrap{
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  .cancel_btn{
    width: 78px;
    height: 44px;
    color: #DFDEE0;
    background-color: #0e0e0e;
    border: 1px solid #545458;
    border-radius: 4px;
  }
  .exam_box{
    border: 1px solid #A0A0A0;
    border-left-width: 0;
    border-right-width: 0;
    overflow-y: scroll;
  }
  .exam_box::-webkit-scrollbar{
    width: 4px;
  }
  .exam_box::-webkit-scrollbar-track{
    background-color: #1A1A1B;
  }
  .exam_box::-webkit-scrollbar-thumb{
    width: 4px;
    height: 4px;
    background-color: #403F45;
  }
  table{
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    font-size: 12px;
  }
  thead{
    position: sticky;
    top: 0;
    background-color: #1A1A1B;
  }
  th{
    height: 42px;
    color: #A0A0A0;
    border-bottom: #606060 solid 1px;
  }
  th:first-child{
    width: 80px;
  }
  th:nth-child(3){
    width: 180px;
  }
  th:nth-child(2), th:nth-child(4){
    width: 227px;
  }
  td{
    height: 36px;
    border-top: 1px solid #303030;
    color: #D0D0D0;
    font-size: 12px;
    text-align: center;
  }
  .item_row:hover{
    background-color: rgba(87,78,224,0.33);
    cursor: pointer;
  }
</style>