<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: "App"
}
</script>

<style>
  body{
    margin: 0;
    background-color: #0e0e0e;
    line-height: 1.5;
    font-size: 15px;
    font-family: Verdana, sans-serif;
  }
  *{
    user-select: none;
  }
  button{
    font-family: Verdana, sans-serif;
  }
  a{
    text-decoration: none;
  }
  p{
    margin: 0;
  }
  button{
    cursor: pointer;
  }
  ul{
    list-style: none;
    padding: 0;
    margin: 0;
  }
  input{
    outline: none;
  }
</style>