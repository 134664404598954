const diseaseList = [
  '--질환을 선택해주세요--',
  '치관부우식증 또는 치질결손',
  '인접면우식증',
  '치경부우식증 또는 마모증',
  '치근우식증',
  '이차우식증',
  '치내치',
  '치외치',
  'Fusion',
  '선천적치아결손',
  '하악관중첩',
  '매복치',
  '치관주위염',
  '치근단방사선투과성',
  '치근단골형성이상',
  '과백악질증',
  '치근파절',
  '잔존치근',
  '치근외흡수',
  '치근내흡수',
  '과잉치',
  '타석',
  '림프절석회화',
  '편도석회화',
  '경동맥석회화',
  '정맥석',
  '경상설골인대골화',
  '상악동석',
  '피부골종',
  '쇼그렌증후군',
  '상악동염',
  '저류가성낭',
  '점막비후',
  '상악동혼탁상',
  'TMJ 골변화',
  '활액연골종증',
  '골연골증',
  '방사선투과성',
  '방사선불투과성',
  '방사선혼합성',
  '하악골설측함요',
  '치아종',
  '비구개관낭',
  '섬유형성이상',
  '골종',
  '골절',
  '골석화증',
  '악성종양',
  '골다공증'
];

export default diseaseList;