<template>
  <div class="login">
    <div class="login_box">
      <div class="title">
        <h2 class="logo">
          <img src="https://panvi.kr/images/mn_logo-0567edf1a4bb2c2ef7a9..png" alt="logo_img">
        </h2>
        <span class="bar">
          <img src="https://panvi.kr/images/login_bar-f5007f834206cc0b9f7e..png" alt="bar">
        </span>
        <div class="title_txt">
          <p>치과영상 원격판독 시스템</p>
          <p>판독의용</p>
        </div>
      </div>
      <ul class="login_input_wrap">
        <li class="login_id">
          <div class="id_icon"></div>
          <input type="text" ref="loginId" v-model="loginInfo.id" placeholder="아이디를 입력하세요">
        </li>
        <li class="login_pw">
          <div class="pw_icon"></div>
          <input @keydown="_enterLogin" type="password" ref="loginPw" v-model="loginInfo.pw" placeholder="비밀번호를 입력하세요">
        </li>
        <li class="login_btn" @click="login">
          <button>로그인</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import wsMixin from '@/utils/wsMixin';
export default {
  name: "PageLogin",
  mixins : [wsMixin],
  data(){
    return{
      loginInfo : {
        id : '',
        pw : ''
      }
    }
  },
  methods : {
    login(){
      if(this.loginInfo.id.length<= 0 || this.loginInfo.pw.length <= 0) return alert('아이디와 비밀번호를 입력해 주세요');
      let userInfo = this.$$build(
        11,
        [
          {
            contentType : 1,
            content : {
              type : "LABELER",
              email : this.loginInfo.id,
              passwd : this.loginInfo.pw
            }
          }
        ]
      );
      this.$socket.send(userInfo);
    },
    _enterLogin(ev){
      // keydown 이벤트가 발생했을 때, keyCode 가 13 (= 엔터) 인 경우, 로그인 함수를 호출한다.
      if(ev.keyCode == 13) this.login();
    },
    onRecv(data){
      // 11번 액션(로그인)에 대한 응답이 수신되었을 때의 처리
      if(data.action == 11){
        /*
        ** 액션에 대한 응답이 성공적일 때의 처리
        ** 새로고침시 로그인 처리를 다시 하는데 사용하기 위하여 아이디, 비밀번호를 세션스토리지에 저장한다.
        ** 로그인이 성공했음을 isLoggedIn 에 boolean값으로 저장한 후, 메인페이지로 이동한다.
        */
        if(data.body[0].content.success){
          sessionStorage.setItem('id',this.loginInfo.id);
          sessionStorage.setItem('passwd',this.loginInfo.pw);
          this.$store.state.socket.isLoggedIn = true;
          this.$router.push('/main');
        }
        /*
        ** 액션에 대한 응답이 실패일 때의 처리
        ** 로그인 실패를 알리는 알림창을 띄운다.
        ** 사용자가 다시 비밀번호를 작성하는데 편리하도록 
        ** 비밀번호를 담고있는 state에 빈 문자열을 대입한 후, 비밀번호를 입력하는 input에 focus를 준다.
        */
        else{
          alert('로그인에 실패하셨습니다.\n다시 시도해주세요');
          this.loginInfo.pw = "";
          this.$refs.loginPw.focus();
        }
      }
    },
  },
  mounted(){
    this.$refs.loginId.focus();
  }
}
</script>

<style scoped>
  .login{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('https://panvi.kr/images/login_bg-31e4946fdf06120f2d45..jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  .login_box{
    width: 650px;
    height: 446px;
    box-sizing: border-box;
    padding: 0 58px;
    border: 1px solid #848488;
    border-radius: 12px;
  }
  .title{
    margin-top: 60px;
    margin-bottom: 52px;
  }
  .title p{
    color: #fff;
    font-weight: 500;
    line-height: 50px;
  }
  .logo{
    margin: 0;
    float: left;
  }
  .bar{
    margin: 18px 24px 0 24px;
    float: left;
  }
  .title_txt{
    display: flex;
    flex-direction: column;
  }
  .title_txt p{
    height: 30px;
    display: flex;
    align-items: center;
  }
  .login_id, 
  .login_pw{
    position: relative;
    height: 60px;
    margin-bottom: 24px;
    display: grid;
    grid-template-columns: 67px 1fr;
    background-color: rgba(223,222,224,0.1);
    border-radius: 4px;
  }
  .login_id input, 
  .login_pw input{
    position: relative;
    font-size: 14px;
    color: #B7B7B7;
  }
  .login_id input:focus::-webkit-input-placeholder,
  .login_pw input:focus::-webkit-input-placeholder{
    color: #B7B7B7;
    transition: opacity 0.3s;
    font-size: 14px;
  }
  .login_id input:focus::-webkit-input-placeholder,
  .login_pw input:focus::-webkit-input-placeholder{
    opacity: 0;
  }
  input{
    background: none;
    outline: none;
    border: none;
  }
  .id_icon{
    background-image: url('https://panvi.kr/images/login_id-a4b50c9cb80c110c89a5..png');
    background-repeat: no-repeat;
    background-position: 28px center;
  }
  .pw_icon{
    background-image: url('https://panvi.kr/images/login_pw-dc722f6ea9bcafab3367..png');
    background-repeat: no-repeat;
    background-position: 28px center;
  }
  .login_btn{
    width: 100%;
    height: 42px;
  }
  .login_btn button{
    display: block;
    width: 100%;
    height: 100%;
    font-size: 20px;
    text-align: center;
    background: linear-gradient(169.8deg, #9560F5 4.58%, #574EE0 92.07%);
    color: #fff;
    border: none;
    border-radius: 4px;
    font-weight: 900;
  }
</style>